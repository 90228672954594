@import '../../styles/customMediaQueries.css';

.favoritesButtonWrapper {
    transform: scale(0.8);
    padding: 10px;
}

.favoritesButtonNotSelected {
    cursor: pointer;
    display: block;

    transform: scale(1.5);

    &:hover {
        transform: scale(1.6);
        cursor: pointer;
    }
}

.favoritesButtonSelected {
    cursor: pointer;
    display: block;
    transform: scale(1.5);

    &:hover {
        cursor: pointer;
        transform: scale(1.6);
    }
}