.root {
  display: flex;
  flex-direction: row;
}

.allWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}



.wrapper {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}

.header {
  composes: h2 from global;
  color: var(--marketplaceColor);
  margin-top: 10px;
}

.item {
  composes: h2 from global;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 11px;
  padding: 0;
}

.itemAllWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  width: 240px;
  flex-wrap: nowrap;
}

.icon {
  margin-right: 5px;
  height: 2px;
  font-size: small;
}

.allCategoriesWrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  padding: 20px;
}

.icon {
  color: var(--marketplaceColor);
  width: 40px;
  height: 35px;
}